<template>
  <section>
    <Dialog
      header="Cadastre-se"
      :visible.sync="modal"
      :style="{ width: '65vw' }"
      :modal="true"
      :closable="false"
      :closeOnEscape="false"
    >
      <div v-if="validateSub">
        <div class="row">
          <div
            class="col-12 d-flex justify-content-center flex-column text-center"
          >
            <img src="@/assets/images/sms_message.svg" alt="sms" />
            <h4>
              Enviamos uma mensagem para
              <b class="color-secondary">{{ cadastro.email }}</b>
            </h4>
            <p>Confirme seu endereço de email informando o código abaixo</p>
            <div class="position-relative">
              <InputText
                id="cpf"
                :disabled="loading"
                class="form-input d-flex mx-auto my-3 center-input"
                type="text"
                placeholder="****"
                required
                v-model="codeMail"
              length="4"
              max-length="4"
                @keyup="handleTokenValidate"
              />
            </div>

            <small   v-if="!loading"
              >Não recebeu sua mensagem ?
              <b @click="sendToken" class="cursor-pointer"
                >Clique aqui para reenviar</b
              ></small>
            <div
              v-if="loading"
              class="spinner-grow text-primary d-flex mx-auto"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
      <form v-else v-on:submit.prevent="registerUser" autocomplete="off">
        <div class="form-row">
          <div class="form-group col-md-8">
            <label for="username" class="fs-12 color-text">Nome Completo</label>
            <InputText
              id="username"
              class="form-input"
              type="text"
              name="username"
              v-model="cadastro.name"
              required
            />
          </div>
          <div class="form-group col-md-4">
            <label for="username" class="fs-12 color-text"
              >E-mail de Contato</label
            >
            <InputText
              id="mail"
              class="form-input"
              type="text"
              email="email"
              v-model="cadastro.email"
              required
            />
          </div>
          <div class="form-group col-md-3">
            <label for="password" class="fs-12 color-text"
              >Tipo de pessoa</label
            >
            <SelectButton
              v-model="cadastro.tipo"
              :options="tipoPessoa"
              optionLabel="name"
            />
          </div>

          <div class="form-group col-md-5">
            <label
              for="cpf"
              class="fs-12 color-text"
              v-if="cadastro.tipo.code != 'pj'"
              >CPF</label
            >
            <label for="cnpj" class="fs-12 color-text" v-else>CNPJ</label>
            <InputText
              v-if="cadastro.tipo.code != 'pj'"
              id="cpf"
              class="form-input"
              type="text"
              required
              v-model="cadastro.cpf"
              v-mask="['###.###.###-##']"
            />
            <InputText
              v-else
              id="cnpj"
              class="form-input"
              type="text"
              required
              v-model="cadastro.cnpj"
              v-mask="['##.###.###/####-##']"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="celular" class="fs-12 color-text"
              >Telefone Celular</label
            >
            <InputText
              id="celular"
              class="form-input"
              type="text"
              v-model="cadastro.telefone"
              v-mask="['(##) ####-####', '(##) #####-####']"
              required
            />
          </div>

          <div class="form-group col-md-12"><hr /></div>
          <div class="form-group col-md-6">
            <label for="password" class="fs-12 color-text">Senha</label>
            <Password autocomplete="off" class="form-input" v-model="cadastro.password" required strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"  weakLabel="Fraca" mediumLabel="Média" strongLabel="Ótimo!" :feedback="true" promptLabel="Insira sua senha" />
            <!-- <InputText
              id="password"
              name="password"
              class="form-input"
              type="password"
              v-model="cadastro.password"
              autocomplete="new-password"
              required
            /> -->
            <small>Ao menos 8 caractéres com uma letra maiúscula.</small>
          </div>
          <div class="form-group col-md-6">
            <label for="password_confirmation" class="fs-12 color-text"
              >Repetir senha</label
            >
            <InputText
              id="password_confirmation"
              name="password_confirmation"
              class="form-input"
              type="password"
              v-model="cadastro.password_confirmation"
              required
            />
              <small v-if="cadastro.password_confirmation.length" v-show="cadastro.password_confirmation != cadastro.password" class="text-danger">As senhas não coincidem</small>
          </div>
          <div class="form-group col-md-12">
            <div
              class="flex-row d-flex ml-auto justify-content-end align-items-center"
            >
              <span class="btn-close pr-3 small" @click="closeModal"
                >fechar</span
              >
              <button
                type="submit"
                class="btn btn-success"
                style="width: 250px"
                :disabled="loading"
              >
                <div
                  class="spinner-grow text-light"
                  role="status"
                  v-if="loading"
                ></div>
                <span class="fs-14 text-uppercase" v-if="!loading"
                  >Cadastrar</span
                >
              </button>
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  </section>
</template>

<script>
import Dialog from "primevue/dialog";
import SelectButton from "primevue/selectbutton";
import Password from 'primevue/password';
export default {
  name: "ModalCadastro",
  components: { Dialog, SelectButton, Password },
  props: ["displayModal"],
  data() {
    return {
      validateSub: false,
      codeMail: "",
      cadastro: {
        tipo: { name: "Física", code: "pf" },
        name: "",
        email: "",
        password_confirmation: "",
        password: ""
      },
      loading: false,
      tipoPessoa: [
        { name: "Física", code: "pf" },
        { name: "Jurídica", code: "pj" },
      ],
    };
  },
  computed: {
    modal: {
      get() {
        return this.displayModal;
      },
      set(value) {
        this.displayModal = value;
      },
    },
  },
  methods: {
    changeModal() {
      this.$emit("changeModal");
    },
    openModal() {
      this.displayModal = true;
    },
    closeModal() {
      this.changeModal();
    },
    registerUser() {
      this.loading = true;
      var code = this.cadastro.tipo.code;
      this.cadastro.tipo = code;
      this.$http
        .post("/cadastro", this.cadastro)
        .then((response) => {
          if (response.data.msg.email) {
            this.$toast.add({
              severity: "error",
              summary: "login",
              detail: response.data.msg.email[0],
              life: 3000,
            });
          } else {
            this.validateSub = true;
            this.$toast.add({
              severity: "success",
              summary: "Cadastro",
              detail: "Cadastro Realizado, verifique seu e-mail.",
              life: 3000,
            });
            console.log(response);
          }

          this.loading = false;
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "login",
            detail: "message",
            life: 3000,
          });
          this.loading = false;
          console.log(error);
        });
    },
    handleTokenValidate() {
      if (this.codeMail.length > 3) {
        this.validateCode();
      }
    },
    //valida token e loga usuário
    validateCode() {
      this.loading = true;
      this.$http
        .post("/valida-user", { token: this.codeMail })
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: "Cadastro",
            detail: response.data.msg,
            life: 3000,
          });
          this.loading = false;
          window.sessionStorage.token = `Bearer ${response.data.user.token}`;
          window.sessionStorage.user = JSON.stringify(response.data.user);
          this.$store.commit("UPDATE_USER", response.data.user);
          this.$store.dispatch("login", response.data.status);
        })
        .then(() => {
          if (this.$route.query.id) {
            this.$router.push({ name: "Subscribe" });
          } else {
            this.$router.push({ name: "Home" });
          }
        })
        .catch((error) => {
          this.codeMail = ''
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: "Cadastro",
            detail: error.response.data.msg,
            life: 3000,
          });
        });
    },
    sendToken() {
      this.loading = true;
      this.$http
        .post("/envia-token", { email: this.cadastro.email })
        .then((response) => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: "Cadastro",
            detail: response.data.msg,
            life: 3000,
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: "Cadastro",
            detail: error.response.data.msg,
            life: 3000,
          });
        });
    },
  },
};
</script>

<style lang="scss">
.p-selectbutton .p-button.p-highlight,
.p-selectbutton .p-button:focus.p-highlight {
  background: $tertiary;
  border-color: $tertiary;
  color: white;
}
</style>