<template>
  <section class="login">
    <div class="container-fluid">
      <div class="row">
        <div
          class="col-12 d-flex justify-content-center align-items-center d-md-none bg-white shadow py-3 position-sticky"
          style="top: 0; z-index: 2"
        >
          <img src="@/assets/images/logo.svg" alt="Logo" class="img-fluid" />
        </div>
        <div
          class="col-md-3 position-relative side_menu vh-100 d-none d-md-flex justify-content-start align-items-center"
        >
          <img
            src="@/assets/images/logo_white.svg"
            alt="Logo"
            class="img-fluid position-absolute"
            style="z-index: 2; top: 15px; left: 15px"
          />


          <hr class="divisor-2" />
        </div>
        <div
          class="col-md-8 offset-md-1 vh-100 login_side d-flex align-items-center"
        >
          <div class="row vw-100">
            <div class="col-md-7 offset-md-1">
              <div class="card shadow-sm border-0 p-2">
                <div class="card-body">
                  <h5
                    class="color-text d-flex fs-16 f-600"
                    @click="toast('success', 'Status login', 'logado')"
                  >
                    <feather
                      class="color-tertiary is-16 mr-2"
                      type="user"
                    ></feather>
                    Login - Painel administrativo
                  </h5>
                  <hr class="divisor" />
                  <form action @submit.prevent="doLogin" v-if="!forgotPassword">
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <label for="username" class="fs-12 color-text"
                          >E-mail</label
                        >
                        <InputText
                          id="username"
                          class="form-input"
                          type="text"
                          v-model="userData.email"
                          required
                        />
                      </div>
                      <div class="form-group col-md-12 position-relative">
                        <label for="username" class="fs-12 color-text"
                          >Senha</label
                        >
                        <InputText
                          id="pass"
                          class="form-input"
                          :type="textType"
                          v-model="userData.password"
                          autocomplete="current-password"
                          required
                        />
                        <feather
                          class="color-tertiary eye is-16 mr-1"
                          :type="visibility"
                          @click="switchPassword"
                        ></feather>
                      </div>
                      <div class="form-group col-md-12">
                        <button
                          type="submit"
                          class="btn btn-success"
                          :disabled="loading"
                        >
                          <div
                            class="spinner-grow text-light"
                            role="status"
                            v-if="loading"
                          ></div>
                          <span v-else> Entrar</span>
                        </button>
                        <a
                          href="#"
                          @click="handleForm"
                          class="small fs-12 text-center mx-auto d-flex mt-2 justify-content-center align-items-center"
                          >Esqueci minha senha</a
                        >
                      </div>
                    </div>
                  </form>
                  <form action @submit.prevent="forgot" v-else>
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <label for="username" class="fs-12 color-text"
                          >E-mail da conta</label
                        >
                        <InputText
                          id="email_recuperacao"
                          class="form-input"
                          v-model="userData.email"
                          type="text"
                          required
                        />
                      </div>
                      <div class="form-group col-md-12">
                        <button type="submit" class="btn btn-success">
                          Solicitar nova senha
                        </button>
                        <a
                          href="#"
                          @click="handleForm"
                          class="small fs-12 text-center mx-auto d-flex mt-2 justify-content-center align-items-center"
                          >voltar para login</a
                        >
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalCadastro :displayModal="display" @changeModal="changeModal($event)" />
    <Toast position="top-right" />
  </section>
</template>

<script>
import Toast from "primevue/toast";
import ModalCadastro from "../../components/ModalCadastro";
export default {
  title: "Prosci - Login",
  name: "Login",
  components: {
    Toast,
    ModalCadastro,
  },
  data() {
    return {
      display: false,
      forgotPassword: false,
      loading: false,
      visibility: "eye",
      textType: "password",
      userData: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    changeModal(state) {
      this.display = state;
    },
    openModal() {
      this.$store.dispatch("modal", true);
    },
    handleForm() {
      this.forgotPassword = !this.forgotPassword;
    },
    switchPassword() {
      this.textType = this.textType == "password" ? "text" : "password";
      this.visibility = this.visibility == "eye" ? "eye-off" : "eye";
    },
    doLogin() {
      this.loading = true;
      this.$http
        .login(this.userData)
        .then((resp) => {
          this.loading = false;
          window.sessionStorage.token = `Bearer ${resp.data.usuario.token}`;
          window.sessionStorage.user = JSON.stringify(resp.data.usuario);
          this.$store.commit("UPDATE_USER", resp.data.usuario);
          this.$store.dispatch("login", resp.data.status);
          this.$router.push({ path: "/" });
        })
        .catch((error) => {
          this.loading = false;
          this.toast("error", "Login", error.response.data.msg);
        });
    },
    toast(severity, sumary, message) {
      this.$toast.add({
        severity: severity,
        summary: sumary,
        detail: message,
        life: 3000,
      });
    },
  },
  created() {
  },
};
</script>

<style lang="scss" scoped>
.login {
  background: url("../../assets/images/maps.svg") no-repeat center center;
  background-size: cover;
}
.side_menu {
  background: url("../../assets/images/login_bg.jpg") no-repeat center center;
  background-size: cover;
  &::before {
    content: "";
    background-color: rgba(49, 48, 131, 0.6);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }
  h1 {
    position: relative;
    z-index: 1;
    &::after {
      content: "";
      position: absolute;
      background: $tertiary;
      bottom: -15px;

      left: 0;
      height: 4px;
      width: 100px;
      color: $tertiary;
    }
  }
}

.eye {
  position: absolute;
  top: 55px;
  right: 10px;
  z-index: 2;
  cursor: pointer;
}
</style>